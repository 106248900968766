var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "covid-stats-wrapper" }, [
    _vm.basicStats
      ? _c("div", { staticClass: "basic-stats" }, [
          _c("div", { staticClass: "active-cases stat-wrap" }, [
            _c("span", { staticClass: "lbl" }, [_vm._v("Active Cases")]),
            _c("span", { staticClass: "val" }, [
              _vm._v(_vm._s(_vm._f("numberFormat")(_vm.basicStats.active))),
            ]),
          ]),
          _c("div", { staticClass: "more-stats" }, [
            _c("div", { staticClass: "stat-wrap" }, [
              _c("span", { staticClass: "lbl" }, [_vm._v("Total Confirmed")]),
              _c("span", { staticClass: "val total" }, [
                _vm._v(_vm._s(_vm._f("numberFormat")(_vm.basicStats.cases))),
              ]),
            ]),
            _c("div", { staticClass: "stat-wrap" }, [
              _c("span", { staticClass: "lbl" }, [_vm._v("Total Recovered")]),
              _c("span", { staticClass: "val recovered" }, [
                _vm._v(
                  _vm._s(_vm._f("numberFormat")(_vm.basicStats.recovered))
                ),
              ]),
            ]),
            _c("div", { staticClass: "stat-wrap" }, [
              _c("span", { staticClass: "lbl" }, [_vm._v("Total Deaths")]),
              _c("span", { staticClass: "val deaths" }, [
                _vm._v(_vm._s(_vm._f("numberFormat")(_vm.basicStats.deaths))),
              ]),
            ]),
          ]),
        ])
      : _vm._e(),
    _vm.showChart
      ? _c("div", {
          staticClass: "case-history-chart",
          attrs: { id: _vm.chartId },
        })
      : _vm._e(),
    _vm.countryData
      ? _c(
          "div",
          { staticClass: "country-data" },
          _vm._l(_vm.countryData, function (country) {
            return _c(
              "div",
              { key: country.name, staticClass: "country-row" },
              [
                _c("p", { staticClass: "name" }, [
                  _c("img", {
                    staticClass: "flag",
                    attrs: { src: country.flag, alt: "Flag" },
                  }),
                  _vm._v(" " + _vm._s(country.name) + " "),
                ]),
                _c("div", { staticClass: "country-case-wrap" }, [
                  _c("div", { staticClass: "stat-wrap" }, [
                    _c("span", { staticClass: "lbl" }, [_vm._v("Confirmed")]),
                    _c("span", { staticClass: "val total" }, [
                      _vm._v(_vm._s(_vm._f("showInK")(country.cases))),
                    ]),
                  ]),
                  _c("div", { staticClass: "stat-wrap" }, [
                    _c("span", { staticClass: "lbl" }, [_vm._v("Recovered")]),
                    _c("span", { staticClass: "val recovered" }, [
                      _vm._v(_vm._s(_vm._f("showInK")(country.recovered))),
                    ]),
                  ]),
                  _c("div", { staticClass: "stat-wrap" }, [
                    _c("span", { staticClass: "lbl" }, [_vm._v("Deaths")]),
                    _c("span", { staticClass: "val deaths" }, [
                      _vm._v(_vm._s(_vm._f("showInK")(country.deaths))),
                    ]),
                  ]),
                ]),
              ]
            )
          }),
          0
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }